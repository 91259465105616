<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.338 30.225"><path d="M45.248 24.578a.755.755 0 00-.666-.4h-13.6a.755.755 0 00-.534.221l-.78.78a1.752 1.752 0 01-1.233.51H16.904a1.752 1.752 0 01-1.234-.511l-.779-.779a.755.755 0 00-.534-.221H.757a.755.755 0 00-.629 1.174l2.126 3.19a3.773 3.773 0 003.143 1.682h34.547a3.773 3.773 0 003.144-1.683l2.125-3.189a.755.755 0 00.035-.774zm-3.42 3.126a2.265 2.265 0 01-1.886 1.01H5.395a2.264 2.264 0 01-1.886-1.009L2.166 25.69h11.877l.557.557a3.273 3.273 0 002.3.954h11.529a3.272 3.272 0 002.3-.953l.558-.558h11.877zM5.289 22.668a.756.756 0 00.756-.756v-20.4h33.248v20.4a.756.756 0 001.511 0V.756A.755.755 0 0040.048 0H5.289a.755.755 0 00-.756.756v21.156a.756.756 0 00.756.756z"/><path d="M12.091 15.868v4.534a.756.756 0 001.511 0v-4.534a.756.756 0 10-1.511 0zM18.135 11.333V20.4a.756.756 0 101.511 0v-9.067a.756.756 0 10-1.511 0zM24.18 15.868v4.534a.756.756 0 001.511 0v-4.534a.756.756 0 10-1.511 0zM30.225 11.333V20.4a.756.756 0 001.511 0v-9.067a.756.756 0 00-1.511 0z"/><path d="M32.492 6.799a1.511 1.511 0 10-3.011.18l-3.952 2.964a1.514 1.514 0 00-1.188 0l-3.955-2.964a1.511 1.511 0 10-3.011-.18 1.552 1.552 0 00.012.18l-3.952 2.964a1.511 1.511 0 10.918 1.39 1.542 1.542 0 00-.012-.18l3.952-2.964a1.517 1.517 0 001.188 0l3.952 2.964a1.511 1.511 0 103 0l3.952-2.964a1.512 1.512 0 002.105-1.39z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconMarge'
  };
</script>
