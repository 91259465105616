<template>
  <div class="simulateurs">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="simulateurs-content page-layout">
      <div class="simulateurs-content-cards">
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.credit.title')"
            link="/dashboard/simulateurs/credit"
          >
            <MeepIconCredit class="app-icon" />
          </SettingCard>
        </div>
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.frais.title')"
            link="/dashboard/simulateurs/frais"
          >
            <MeepIconFrais class="app-icon" />
          </SettingCard>
        </div>
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.tva.title')"
            link="/dashboard/simulateurs/tva"
          >
            <MeepIconTva class="app-icon" />
          </SettingCard>
        </div>
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.salary.title')"
            link="/dashboard/simulateurs/salary"
          >
            <MeepIconSalary class="app-icon" />
          </SettingCard>
        </div>
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.marge.title')"
            link="/dashboard/simulateurs/marge"
          >
            <MeepIconMarge class="app-icon" />
          </SettingCard>
        </div>
        <div class="simulateurs-content-card">
          <SettingCard
            :isXs="true"
            :title="$t('simulateurs.taxe.title')"
            link="/dashboard/simulateurs/taxe"
          >
            <MeepIconTaxe class="app-icon" />
          </SettingCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import SettingCard from "@/components/SettingCard";
import MeepIconCredit from "@/components/icons/MeepIconCredit.vue";
import MeepIconFrais from "@/components/icons/MeepIconFrais.vue";
import MeepIconTva from "@/components/icons/MeepIconTva.vue";
import MeepIconSalary from "@/components/icons/MeepIconSalary.vue";
import MeepIconMarge from "@/components/icons/MeepIconMarge.vue";
import MeepIconTaxe from "@/components/icons/MeepIconTaxe.vue";

export default {
  name: "Simulateurs",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    SettingCard,
    MeepIconCredit,
    MeepIconFrais,
    MeepIconTva,
    MeepIconTaxe,
    MeepIconSalary,
    MeepIconMarge,
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .simulateurs {
    &-content {
      &-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 205px;
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        gap: toRem(17);
        @include for-lg {
          gap: toRem(25);
          grid-auto-rows: 290px;
        }

        .simulateurs-content-card {
          width: 100%;

          .setting-card.setting-card-xs {
            width: 100%;
          }
        }
      }

      &.page-layout {
        height: 100%;
      }
    }
  }
}
</style>
