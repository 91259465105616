<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.084 36.765"><path d="M35.885 5.646L30.438.198a.679.679 0 00-.48-.2H11.576a2.043 2.043 0 00-2.04 2.041v6.538a.679.679 0 101.357 0V2.039a.684.684 0 01.683-.683h18.1l5.05 5.05v28.317a.684.684 0 01-.683.683H11.576a.684.684 0 01-.683-.683v-.957a.679.679 0 10-1.357 0v.957a2.043 2.043 0 002.04 2.04h22.47a2.043 2.043 0 002.041-2.04v-28.6a.677.677 0 00-.202-.477z"/><path d="M29.277 2.586a.679.679 0 00-.679.679v3.542a.679.679 0 00.679.679h3.542a.679.679 0 100-1.357h-2.864V3.265a.679.679 0 00-.678-.679zM17.021 31.999a.678.678 0 00.678-.678V10.893a.679.679 0 00-.678-.679H.677a.679.679 0 00-.679.679v20.428a.679.679 0 00.679.678zm-15.663-14.3h4.09v3.409h-4.09zm5.447 0h4.09v3.409h-4.09zm9.537 0v3.409h-4.09v-3.409zm0 8.176h-4.09v-3.409h4.09zm-5.447-3.409v3.409h-4.09v-3.409zm-4.09 4.767h4.09v3.409h-4.09zm5.447 3.409v-3.407h4.09v3.409zm4.09-19.07v4.771H1.357v-4.771zM1.357 22.467h4.09v8.176h-4.09zM22.809 15.661a3.062 3.062 0 103.062 3.062 3.065 3.065 0 00-3.062-3.062zm0 4.766a1.7 1.7 0 111.7-1.7 1.706 1.706 0 01-1.7 1.7zM30.299 21.79a3.062 3.062 0 103.062 3.062 3.065 3.065 0 00-3.062-3.062zm0 4.767a1.7 1.7 0 111.7-1.7 1.707 1.707 0 01-1.7 1.7zM21.341 27.749a.679.679 0 00.958-.064l9.533-10.895a.679.679 0 00-1.021-.894l-9.533 10.895a.679.679 0 00.063.958z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconCredit'
  };
</script>
