<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.766 36.766"><path d="M18.383 0a18.383 18.383 0 1018.383 18.383A18.4 18.4 0 0018.383 0zm0 35.41a17.027 17.027 0 1117.028-17.027 17.047 17.047 0 01-17.028 17.028z"/><path d="M18.383 5.449A12.948 12.948 0 005.449 18.488a13.18 13.18 0 002.906 8.061l.088.111a.024.024 0 00.019.01h5.786a4.188 4.188 0 008.267 0h5.635a.336.336 0 00.264-.127 13.168 13.168 0 002.9-8.046A12.948 12.948 0 0018.383 5.449zm0 23.381a2.838 2.838 0 112.838-2.838 2.841 2.841 0 01-2.838 2.838zm9.3-13.187l1.789-.6a11.526 11.526 0 01.483 3H28.07a.339.339 0 00-.339.339.339.339 0 00.339.339h1.881a11.432 11.432 0 01-2.293 6.592H22.52a4.2 4.2 0 00-2.246-3.062l2.071-6.578a.677.677 0 00-.443-.85.678.678 0 00-.85.443l-2.071 6.578a4.12 4.12 0 00-.594-.047 4.2 4.2 0 00-4.134 3.516H9.115a11.439 11.439 0 01-2.294-6.593h1.881a.338.338 0 00.339-.339.339.339 0 00-.339-.339H6.816a11.527 11.527 0 01.484-3l1.789.6a.339.339 0 00.429-.215.339.339 0 00-.215-.428l-1.791-.6a11.557 11.557 0 011.339-2.58l1.519 1.113a.339.339 0 00.474-.073.338.338 0 00-.073-.474l-1.517-1.112a11.676 11.676 0 012.029-2.029l1.112 1.518a.339.339 0 00.474.073.339.339 0 00.073-.474l-1.113-1.519a11.577 11.577 0 012.58-1.339l.6 1.791a.339.339 0 00.428.214.339.339 0 00.215-.428l-.6-1.79a11.523 11.523 0 013-.483v1.886a.339.339 0 00.339.339.339.339 0 00.339-.339V6.814a11.521 11.521 0 013 .483l-.6 1.79a.338.338 0 00.214.428.339.339 0 00.428-.214l.6-1.791a11.563 11.563 0 012.579 1.339l-1.113 1.519a.339.339 0 00.073.474.339.339 0 00.474-.073l1.112-1.517a11.672 11.672 0 012.029 2.029l-1.517 1.112a.339.339 0 00-.073.474.339.339 0 00.474.073l1.519-1.113a11.564 11.564 0 011.339 2.579l-1.791.6a.339.339 0 00-.214.429.339.339 0 00.417.208z"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconFrais'
  };
</script>
